import {
  Accordion,
  AccordionContent,
  AccordionLabel,
  IAccordionItem,
  Text,
} from '@prescriberpoint/ui';
import {useMemo} from 'react';
import JumpingLinks from '../IndicationPage/JumpingLinks';
import {INDICATION_TEMPLATE} from '@/data/indicationLandingPage';
import {MOST_VIEWED} from '@/data/mostViewed';
import {LabelListItem} from '@/models';
import {NextPageWithLayout} from '@/pages/_app';
import {MostViewedProps} from '@/pages/most-viewed';
import {capitalize, joinWords, replaceVariablesInStr} from '@/utils/string';

const AccLabel = ({
  drug,
  id,
  ...props
}: {
  drug: LabelListItem;
  selected: boolean;
  handleClick: () => void;
  id?: string;
}) => {
  const linkProps = {
    href: `/therapies/${drug.slug}`,
    label: drug.labelTitle,
  };

  return (
    <AccordionLabel
      headerCasing='lower'
      header={`(${joinWords(drug.ingredients, 'and')})`}
      label={capitalize(drug.labelTitle)}
      {...props}
      linkProps={linkProps}
      id={id}
    />
  );
};

const AccContent = ({
  description,
  drug,
}: {
  drug: LabelListItem;
  description: string;
}) => (
  <AccordionContent
    content={replaceVariablesInStr(description, {
      labelTitle: capitalize(drug.labelTitle),
      type: drug.generic ? 'generic' : 'brand',
      ingredients: joinWords(drug.ingredients, 'and'),
      indications: joinWords(drug.indications, 'and'),
      classes: joinWords(drug.classes, 'and'),
    })}
  />
);

const MostViewedPage: NextPageWithLayout<MostViewedProps> = ({drugs}) => {
  const items: IAccordionItem[] = useMemo(() => {
    const exist: Record<string, boolean> = {};

    return drugs.map((drug, idx) => {
      let id: string | undefined = undefined;
      const firstLetter = drug.labelTitle?.toLocaleLowerCase().charAt(0);
      if (!exist[firstLetter]) {
        id = firstLetter;
        exist[firstLetter] = true;
      }
      const description = INDICATION_TEMPLATE.drugDescriptions[idx % 3];
      return {
        label: (props) => <AccLabel id={id} drug={drug} {...props} />, // NOSONAR
        content: () => <AccContent drug={drug} description={description} />, // NOSONAR
      };
    });
  }, [drugs]);

  const {title, bottomText} = MOST_VIEWED;

  return (
    <div className='space-y-6 p-4 py-10 md:px-40 lg:px-80'>
      <div className='inline-flex flex-1 flex-col items-start justify-start space-y-2'>
        <Text as='headline-lg' weight='medium' tag='h1'>
          {title}
        </Text>
        <Text as='body-md' tag='div'>
          {bottomText}
        </Text>
        <JumpingLinks />
      </div>
      <div className='no-inline-border mb-2 rounded-lg bg-white shadow-md'>
        <Accordion accordionItems={items} id='indication' />
      </div>
    </div>
  );
};

export default MostViewedPage;
